/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Card } from "primereact/card";
import "./Bookings.css";
import { message, Spin, Radio, Form, Select, Table, Tag } from "antd";
import { Menu } from "antd";
import { Button } from "primereact/button";
import { GetDriverByAdmin } from "../../services/Api/Api";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  GetBookingById,
  UpdateBooking,
  GetBookingDetailsByBookingId,
} from "../../services/Api/BookingApi";

const EditBookings = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [driverList, setDriverList] = useState([]);
  const [bookingDetailsData, setBookingDetailsData] = useState([]);
  const [showRejectionOptions, setShowRejectionOptions] = useState(false);
  const [rejectionType, setRejectionType] = useState(null);

  const [bookingData, setBookingData] = useState({
    booking_status: "",
    booking_id: "",
    service_provider_ids: [],
    team_leader_id: "",
  });
  const [defaultTeamLeader, setDefaultTeamLeader] = useState(null);
  const [loading, setLoading] = useState(true);

  //get drivers name
  const getDriversList = async () => {
    setLoading(true); // Set loading to true before the API call
    let res = await GetDriverByAdmin(id);
    if (res?.status === 200) {
      const drivers = res?.data?.data;
      // Find the team leader and set the default value
      const teamLeader = drivers?.find((driver) => driver.is_team_leader);
      if (teamLeader) {
        setDefaultTeamLeader(teamLeader.user_profile?.name);
      }

      setDriverList(drivers);
    } else {
      // Handle the error here
    }
    setLoading(false); // Set loading to false once data is fetched
  };

  useEffect(() => {
    getDriversList();
  }, []);

  //get booking by id
  useLayoutEffect(() => {
    const fetchBookingData = async () => {
      try {
        const res = await GetBookingById(id);
        setBookingData(res.data.data);
        form.setFieldsValue(res.data.data);
      } catch (error) {
        console.error("Failed to fetch booking data:", error);
      }
    };

    fetchBookingData();
  }, [id]);

  const bookingid = bookingData.booking_id;
  useLayoutEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const res = await GetBookingDetailsByBookingId(bookingid);
        setBookingDetailsData(res.data.data.booking_details);
      } catch (error) {
        console.error("Failed to fetch booking details:", error);
      }
    };

    if (bookingid) {
      fetchBookingDetails();
    }
  }, [bookingid]);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const handleDriverChange = (values) => {
    // Find the selected drivers by name
    const selectedDrivers = driverList.filter((driver) =>
      values.includes(driver.user_profile?.name)
    );

    // Extract driver IDs from selected drivers
    const selectedDriverIds = selectedDrivers.map((driver) => driver.id);

    setBookingData({
      ...bookingData,
      service_provider_ids: selectedDriverIds, // Update the state with selected driver IDs
    });
  };

  const handleTeamLeaderChange = (value) => {
    // Find the selected team leader by name
    const selectedTeamLeader = driverList.find(
      (driver) => driver.user_profile?.name === value
    );

    // Extract team leader ID from the selected team leader
    const selectedTeamLeaderId = selectedTeamLeader
      ? selectedTeamLeader.id
      : "";

    setBookingData({
      ...bookingData,
      team_leader_id: selectedTeamLeaderId, // Update the state with the selected team leader ID
    });
  };

  const handleRejectionTypeChange = (e) => {
    setRejectionType(e.target.value);
  };
  const [bookingStatus, setBookingStatus] = useState(
    bookingData?.booking_status || ""
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("booking_id", bookingData?.id ? bookingData?.id : "");
    formData.append(
        "service_provider_ids",
        bookingData?.service_provider_ids ? bookingData?.service_provider_ids : ""
    );

    // Use bookingStatus from state, not bookingData
    formData.append("booking_status", bookingStatus);

    // If booking status is REJECTED, append rejection_type
    if (bookingStatus === "REJECTED") {
        formData.append("rejection_type", rejectionType ? rejectionType : "particular");
    }

    formData.append(
        "team_leader_id",
        bookingData?.team_leader_id ? bookingData?.team_leader_id : ""
    );

    // Call the API to update the booking
    UpdateBooking(formData)
        .then((res) => {
            if (res.status === 201) {
                message.success("Booking updated successfully!");
            }
            navigate("/bookings");
        })
        .catch((err) => {
            if (err.response && err.response.status === 401) {
                message.error("Token expired!");
                localStorage.removeItem("adminToken");
                setTimeout(() => {
                    navigate("/Login");
                }, 3000);
            } else {
                message.error("Something went wrong");
            }
        });
};

  const navigateToBooking = () => {
    navigate("/bookings");
  };

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "booking_details_unique_id",
      key: "booking_details_unique_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Booking Status",
      dataIndex: "booking_status",
      key: "booking_status",
      render: (status) => {
        let color;
        switch (status) {
          case "ACCEPTED":
            color = "green";
            break;
          case "PENDING":
            color = "blue";
            break;
          case "REJECTED":
            color = "red";
            break;
          case "ONGOING":
            color = "orange";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Employees",
      dataIndex: "service_provider_details",
      key: "service_providers",
      render: (serviceProviders) => {
        const serviceProviderNames = serviceProviders
          .filter((provider) => provider.service_provider_profile)
          .map(
            (provider) => provider.service_provider_profile.user_profile.name
          )
          .join(", ");
        return serviceProviderNames ? (
          serviceProviderNames
        ) : (
          <span style={{ color: "red", fontWeight: 300 }}>
            No Employee assigned
          </span>
        );
      },
    },
    {
      title: "Team Leader",
      dataIndex: "service_provider_details",
      key: "team_leader",
      render: (serviceProviders) => {
        const teamLeader = serviceProviders.find(
          (provider) => provider.is_team_leader
        );
        return teamLeader ? (
          teamLeader.service_provider_profile.user_profile.name
        ) : (
          <span style={{ color: "red", fontWeight: 300 }}>
            No team leader assigned
          </span>
        );
      },
    },
    {
      title: "Service Start Time",
      dataIndex: "service_start_time",
      key: "service_start_time",
      render: (time) => (time ? new Date(time).toLocaleString() : "---"),
    },
    {
      title: "Service End Time",
      dataIndex: "service_end_time",
      key: "service_end_time",
      render: (time) => (time ? new Date(time).toLocaleString() : "---"),
    },
  ];
  const handleStatusChange = (value) => {
    // Show rejection options only when the status is 'REJECTED'
    if (value === "REJECTED") {
      setShowRejectionOptions(true);
    } else {
      setShowRejectionOptions(false);
      setRejectionType(null); // Reset rejection type when not rejected
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <div>
          <h3 className="page-title">BOOKING MANAGEMENT</h3>
          <p style={{ color: "green" }} className="page-sub-title">
            Edit Details of User's Booking By Providing Employee or changing the
            status
          </p>
        </div>
        <div>
          <Button
            icon="pi pi-arrow-left"
            severity="secondary"
            onClick={navigateToBooking}
            style={{ borderRadius: "5px", height: "47px" }}
          >
            <span style={{ marginLeft: "5px" }}>Return to Bookings</span>
          </Button>
        </div>
      </Box>

      <Form form={form} onFinish={onFinish}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card style={{ width: "30%", marginRight: "20px" }}>
            <div
              style={{
                margin: "0 0 7px 0px",
              }}
            >
              <h4
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "600",
                  marginBottom: "20px",
                  color: "#1677FF",
                }}
              >
                General Information :
              </h4>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Booking Id:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.booking_details_unique_id}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                User Name:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.bookings?.booking_user?.user_profile?.name}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Service Name:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.bookings?.service_booking?.name}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Booking Type:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.bookings?.type}
              </p>
              {bookingData?.bookings?.type === "Recurring" && (
                <>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "darkgray",
                    }}
                  >
                    Recurring Every:
                  </h5>
                  <p style={{ marginBottom: "20px" }}>
                    {bookingData?.bookings?.recurring_every}
                  </p>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "darkgray",
                    }}
                  >
                    Occurrence:
                  </h5>
                  <p style={{ marginBottom: "20px" }}>
                    {bookingData?.bookings?.occurence}
                  </p>
                </>
              )}

              {bookingData?.bookings?.type === "Non-Recurring" && (
                <>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "darkgray",
                    }}
                  >
                    Service Start Time:
                  </h5>
                  <p style={{ marginBottom: "20px" }}>
                    {bookingData?.service_start_time
                      ? new Date(
                          bookingData.service_start_time
                        ).toLocaleString()
                      : "---"}
                  </p>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "darkgray",
                    }}
                  >
                    Service End Time:
                  </h5>
                  <p style={{ marginBottom: "20px" }}>
                    {bookingData?.service_end_time
                      ? new Date(bookingData.service_end_time).toLocaleString()
                      : "---"}
                  </p>
                </>
              )}
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Date:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData.date && bookingData?.date.split("T")[0]}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Time:
              </h5>
              <p style={{ marginBottom: "20px" }}>{bookingData?.time}</p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Addreess:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.bookings?.booking_address?.full_address}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Booking Status:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.booking_status}
              </p>
              <h5
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Cerebri Sans,sans-serif",
                  fontWeight: "700",
                  marginTop: "14px",
                  color: "darkgray",
                }}
              >
                Notes:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                {bookingData?.bookings?.notes}
              </p>
            </div>
          </Card>

          <Card style={{ width: "70%" }}>
            <h4
              style={{
                fontSize: "1.2rem",
                fontFamily: "Cerebri Sans,sans-serif",
                fontWeight: "600",
                marginBottom: "20px",
                color: "#1677FF",
              }}
            >
              Change Booking Status and assign employee and team Leader :
            </h4>
            {loading ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }}
              />
            ) : (
              <>
                <Form.Item
                  label="Select Booking Status:"
                  name="booking_status"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select booking status"
                    onChange={(value) => {
                      setBookingStatus(value); // Update the state when status changes
                      handleStatusChange(value); // Optional if you need to keep existing functionality
                    }}
                    defaultValue={bookingData?.booking_status}
                    options={[
                      {
                        value: "PENDING",
                        label: "PENDING",
                        disabled: true,
                      },
                      {
                        value: "ACCEPTED",
                        label: "ACCEPTED",
                      },
                      {
                        value: "REJECTED",
                        label: "REJECTED",
                      },
                    ]}
                  />
                </Form.Item>

                {showRejectionOptions && (
                  <Form.Item
                    name="rejectionType"
                    label="Rejection Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a rejection type",
                      },
                    ]}
                  >
                    <Radio.Group onChange={handleRejectionTypeChange}>
                      <Radio value="particular">Reject this booking</Radio>
                      <Radio value="following_event">
                        Reject all the following bookings
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}

                <Form.Item
                  label="Select Employee:"
                  name="service_provider_ids"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select an employee"
                    mode="multiple"
                    onChange={handleDriverChange}
                    defaultValue={bookingData.service_provider_ids?.map(
                      (id) =>
                        driverList?.find((driver) => driver.id === id)
                          ?.user_profile?.name
                    )}
                  >
                    {driverList?.map((driver) => (
                      <Select.Option
                        key={driver.id}
                        value={driver.user_profile?.name}
                        disabled={driver.is_service_provider}
                      >
                        {driver.user_profile?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Select Team Leader:"
                  name="team_leader_id"
                  labelCol={{ span: 24 }}
                  initialValue={defaultTeamLeader}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select a team leader"
                    onChange={handleTeamLeaderChange}
                    value={defaultTeamLeader}
                    disabled={loading}
                  >
                    {driverList?.map((driver) => (
                      <Select.Option
                        key={driver.id}
                        value={driver.user_profile?.name}
                        // disabled={driver.is_team_leader === false}
                      >
                        {driver.user_profile?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button
                    icon="pi pi-check"
                    severity="info"
                    onClick={handleSubmit}
                    style={{
                      marginTop: "100px",
                      height: "45px",
                      padding: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    Submit{" "}
                  </Button>
                </Form.Item>
              </>
            )}
          </Card>
        </div>
      </Form>

      {bookingData?.bookings?.type === "Recurring" && (
        <Card
          style={{
            width: "100%",
            marginTop: "50px",
          }}
        >
          <h4
            style={{
              fontSize: "1.2rem",
              fontFamily: "Cerebri Sans,sans-serif",
              fontWeight: "600",
              marginBottom: "20px",
              color: "#1677FF",
            }}
          >
            Booking Details:
          </h4>
          <Table
            columns={columns}
            dataSource={bookingDetailsData}
            pagination={{ pageSize: 5 }}
          />
        </Card>
      )}
    </Box>
  );
};

export default EditBookings;
