import React, { useState } from "react";
import { AdminLogin, GetAuthorizationUrl } from "../../services/Api/Api";
import "./Login.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Form, Input, message } from "antd";
import logo from "../../assets/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const onFinish = async (values) => {
    let formData = {
      email: values.email,
      password: values.password,
    };

    try {
      const res = await AdminLogin(formData);
      // message.success("Logged In Successfully");

      const { authorize, token, id, role_id } = res.data.data;

      localStorage.removeItem("adminToken");
      localStorage.setItem("adminToken", token);
      if(localStorage.getItem("authorize") !== true){
        localStorage.setItem("authorize", false);
      }
    

      if (authorize) {
        localStorage.setItem("authorize", true);
        navigate("/");
        message.success("Logged In Successfully");
      } else {
        setLoading(true);
        await handleAuthorization(id, role_id);
      }
    } catch (error) {
      if (error.response.data.message === "Error: User not found.") {
        message.error("Email Doesn't Exist");
      } else if (
        error.response.data.message ===
        "Error: Invalid email or password. Please try again."
      ) {
        message.error("Invalid Password");
      } else if (
        error.response.data.message ===
        "Please Enter Required Fields : [ email_id || password ]"
      ) {
        message.error("Please enter Required Fields");
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleAuthorization = async (id, role_id) => {
  //   try {
  //     const authRes = await GetAuthorizationUrl();
  //     const authorizationUrl = authRes.data.authorizationUrl;
  //     window.location.href = authorizationUrl; 
  //   } catch (error) {
  //     console.error("Error fetching authorization URL:", error);
     
  //     setLoading(false); 
  //   }
  // };

  const handleAuthorization = async (id, role_id) => {
    try {
      const authRes = await GetAuthorizationUrl();
      const { data } = authRes; 
      console.log(data,"data")
      const authorizationUrl = data.authorizationUrl;
  

      window.location.href = authorizationUrl;
      

      // setTimeout(() => {
      //   if (data.status === 201) {
      //     navigate("/");
      //   }
      // }, 2000); 
    } catch (error) {
      console.error("Error fetching authorization URL:", error);
      setLoading(false);
    }
  };
  


//   const handleAuthorization = async (id, role_id) => {
//     try {
//       const authRes = await GetAuthorizationUrl();
//       console.log(authRes,"authRes")
//       const authorizationUrl = authRes.data.authorizationUrl;

//       // Open authorization URL in the same window/tab
//       window.location.href = authorizationUrl;

//       // Check for status 201 after redirection
//       setTimeout(async () => {
//         try {
//           const checkAuthRes = await fetch(authorizationUrl);
//           console.log(checkAuthRes,"checkAuthRes")
//           const { status, message } = await checkAuthRes.json();
          
// console.log(status,message,"statusss")
//           if (status === 201) {
//             message.success("Authorization successful");
//             navigate("/");
//           } else {
//             console.error("Authorization not successful:", message);
//             // Handle unsuccessful authorization
//             setLoading(false);
//           }
//         } catch (error) {
//           console.error("Error checking authorization status:", error);
//           // Handle error checking authorization status
//           setLoading(false);
//         }
//       }, 2000); // Adjust timeout as needed
//     } catch (error) {
//       console.error("Error fetching authorization URL:", error);
//       // Handle error fetching authorization URL
//       setLoading(false);
//     }
//   };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="LoginContainer">
      <div className="Login">
        <div className="Login_Container">
          <div className="LogoContainer">
            <img src={logo} alt="Logo" className="Logo" />
          </div>
          <div className="heading_two_content">
            <h2 className="heading_2">Hi, Welcome Back</h2>
            <span className="heading_two_item">
              Enter Your Credentials To continue
            </span>
          </div>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ required: true, message: "Please enter your EmailId" }]}
            >
              <Input
                style={{
                  height: "55px",
                  borderRadius: "10px",
                  borderColor: "var(--color-c3d4da)",
                  boxShadow: "none",
                }}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please enter your Password" }]}
            >
              <Input.Password
                style={{
                  height: "55px",
                  borderRadius: "10px",
                  borderColor: "var(--color-c3d4da)",
                  boxShadow: "none",
                }}
              />
            </Form.Item>
            <div className="button_div">
              <Button
                severity="secondary"
                htmlType="submit"
                className="login_button"
                loading={loading}
              >
                SIGN IN
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
