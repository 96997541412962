/** @format */

import React, { useEffect, useState } from "react";
import {
	GetUserCount,
	GetServiceCount,
	GetDriverCount,
	GetMostBookedService,
} from "../../services/Api/Api";
import { Button } from "primereact/button";
import {
	GetAllBookingCount,
	GetCompletedCount,
	GetDeletedCount,
	GetPendingCount,
} from "../../services/Api/BookingApi";
import "./Dashboard.css";
import { Card } from "primereact/card";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { BsFillClockFill } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { BsTagsFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router";

const Dashboard = () => {
	const navigate = useNavigate();
	const [driverData, setDriverData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [mostBookedService, setMostBookedService] = useState([]);
	const [bookingCount, setBookingCount] = useState([]);
	const [pendingCount, setPendingCount] = useState([]);
	const [completedCount, setCompletedCount] = useState([]);
	const [deletedCount, setDeletedCount] = useState([]);

	ChartJS.register(ArcElement, Tooltip, Legend);
	const data = {
		labels: mostBookedService.map((item) => item.service_name || ""),

		datasets: [
			{
				data: mostBookedService.map((item) => item.booking_count || 0),
				backgroundColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderWidth: 1,
			},
		],
	};

	mostBookedService.forEach((item) => {
		console.log("Service Booking Name:", item.service_id);
	});

	//get customer count
	const getMostBookedService = async () => {
		try {
			const result = await GetMostBookedService(
				localStorage.getItem("adminToken")
			);
			setMostBookedService(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getCustomerData = async () => {
		try {
			const result = await GetUserCount(localStorage.getItem("adminToken"));
			setCustomerData(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getDriverData = async () => {
		try {
			const result = await GetDriverCount(localStorage.getItem("adminToken"));
			setDriverData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	//get likes count
	const getServiceCount = async () => {
		try {
			const result = await GetServiceCount(localStorage.getItem("adminToken"));
			setServiceData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getBookingCount = async () => {
		try {
			const result = await GetAllBookingCount(
				localStorage.getItem("adminToken")
			);
			setBookingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getPendingBookingCount = async () => {
		try {
			const result = await GetPendingCount(localStorage.getItem("adminToken"));
			setPendingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getCompletedBookingCount = async () => {
		try {
			const result = await GetCompletedCount(
				localStorage.getItem("adminToken")
			);
			setCompletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getDeletedBookingCount = async () => {
		try {
			const result = await GetDeletedCount(localStorage.getItem("adminToken"));
			setDeletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getServiceCount();
		getDriverData();
		getCustomerData();
		getMostBookedService();
		getBookingCount();
		getDeletedBookingCount();
		getCompletedBookingCount();
		getPendingBookingCount();
	}, []);

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					backgroundColor: "white", // Changed to camelCase
					padding: "20px",
					borderRadius: "8px", // Changed to camelCase
					boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
					marginBottom: "20px", // Keep quotes for string values
				}}
			>
				<h3 className="dashboard_title">Welcome Admin 👋🏻</h3>
				<p className="page-sub-title" style={{ color: "green" }}>
					Here’s a snapshot of your latest statistics and insights to help you
					manage the platform effectively.
				</p>
			</div>

			<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
				<div className="card-box small-card">
					<div>
						<p className="desc">Total Users Registered</p>
						<h4 className="mb-0 sub-title">{customerData}</h4>
					</div>
					<Button
						label="	View Users"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#EFF5FB",
							marginTop: "10px",
							border: "#EFF5FB",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/users")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#D7E8F8";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#EFF5FB";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
				<div className="card-box small-card">
					<div>
						<p className="desc">Total Employees Registered</p>
						<h4 className="mb-0 sub-title">{driverData}</h4>
					</div>
					<Button
						label="	View Employees"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#EFF5FB",
							marginTop: "10px",
							border: "#EFF5FB",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/employees")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#D7E8F8";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#EFF5FB";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
				<div className="card-box small-card">
					<div>
						<p className="desc">Total Services</p>
						<h4 className="mb-0 sub-title">{serviceData}</h4>
					</div>
					<Button
						label="	View Services"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#EFF5FB",
							marginTop: "10px",
							border: "#EFF5FB",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/services")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#D7E8F8";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#EFF5FB";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
			</div>

			<div className="d-flex flex-row mb-3 gap-3">
				{/* chart */}
				<div
					style={{
						backgroundColor: "white",
						padding: "20px",
						borderRadius: "8px",
						boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
						width: "40%",
					}}
				>
					<h4 className="mb-3" style={{ fontWeight: "bold" }}>
						Booking Overview
					</h4>
					<Card>
						<Doughnut data={data} />
					</Card>
				</div>

				<div
					style={{
						backgroundColor: "white",
						padding: "20px",
						borderRadius: "8px",
						boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
						width: "60%",
					}}
				>
					<h4 className="mb-3" style={{ fontWeight: "bold" }}>
						Booking Statistics
					</h4>
					<div className="d-flex flex-column gap-4">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<p className="mb-0" style={{ color: "#6B7280" }}>
									Total Bookings:
								</p>
								<h4 className="mb-0">{bookingCount}</h4>
							</div>
							<div
								style={{
									width: "50px",
									height: "50px",
									backgroundColor: "#E0F2FF",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<BsTagsFill color="#007BFF" size={24} />
							</div>
						</div>

						<div className="d-flex justify-content-between align-items-center">
							<div>
								<p className="mb-0" style={{ color: "#6B7280" }}>
									Completed Bookings:
								</p>
								<h4 className="mb-0">{completedCount}</h4>
							</div>
							<div
								style={{
									width: "50px",
									height: "50px",
									backgroundColor: "#FFF3E0",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<FaCheckCircle color="#FF8A00" size={24} />
							</div>
						</div>

						<div className="d-flex justify-content-between align-items-center">
							<div>
								<p className="mb-0" style={{ color: "#6B7280" }}>
									Pending Bookings
								</p>
								<h4 className="mb-0">{pendingCount}</h4>
							</div>
							<div
								style={{
									width: "50px",
									height: "50px",
									backgroundColor: "#E0F7FA",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<BsFillClockFill color="#00B8D4" size={24} />
							</div>
						</div>

						<div className="d-flex justify-content-between align-items-center">
							<div>
								<p className="mb-0" style={{ color: "#6B7280" }}>
									Deleted Bookings:
								</p>
								<h4 className="mb-0">{deletedCount}</h4>
							</div>
							<div
								style={{
									width: "50px",
									height: "50px",
									backgroundColor: "#FDE0E0",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<MdDeleteForever color="#D50000" size={24} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
