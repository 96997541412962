/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

// ****************************************** USER BOOKING *****************************************
export const GetUserBooking = async (adminToken, booking_status, period) => {
	let config = {
		booking_status: booking_status,
		period: period,
	};
	try {
		const res = await axios.post(BASE_URL + "booking/getAllBookings", config, {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetBookingRequestCount = async () => {
	const res = await axios.get(BASE_URL + "booking/checkBookingRequest");
	return res;
};

export const GetBookingById = async (id) => {
	return await axios.get(BASE_URL + `booking/getBookingByIdByAdmin/${id}`);
};

export const GetBookingDetailsByBookingId = async (id) => {
	return await axios.get(BASE_URL + `booking/getBookingDetailsByBookingid/${id}`);
};

export const UpdateBooking = async (formData) => {
	return await axios.post(BASE_URL + `booking/alotBookingToDriver`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

//booking in user pages

export const GetBookingByUserId = async (formData) => {
	try {
		const res = await axios.post(
			BASE_URL + "admin/user/getBookinsByUserId",
			formData
		);
		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetBookingBySPId = async (formData) => {
	try {
		const res = await axios.post(
			BASE_URL + "admin/user/getBookinsBySPId",
			formData
		);
		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

//STATISTICS
export const GetAllBookingCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getBookingCount");
	return res;
};

export const GetPendingCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getPendingBookingCount");
	return res;
};

export const GetCompletedCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getCompletedBookingCount");
	return res;
};

export const GetDeletedCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getDeletedBookingCount");
	return res;
};
