import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { GetUserById } from "../../services/Api/Api";
import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Space, Table, Tag } from "antd";
import { GetBookingBySPId } from "../../services/Api/BookingApi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import "../Customer/Customers.css";
const ViewCustomer = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [userData, setUserData] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [activeTab, setActiveTab] = useState("all");

    useLayoutEffect(() => {
        GetUserById(id)
            .then((res) => {
                setUserData(res.data.data);
                console.log("user", res.data.data);
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }, [id]);

    const getData = async (booking_status) => {
        try {
            const formData = { id: id, booking_status };
            let result = await GetBookingBySPId(formData);
            // Add an auto-increment ID to each booking
            const dataWithIndex = result.data.data.map((item, index) => ({
                ...item,
                autoIncrementId: index + 1,
            }));
            setBookingData(dataWithIndex);
            console.log("userbooking==>", dataWithIndex);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getData("all");
    }, [id]);

    const navigateToUser = () => {
        navigate("/employees");
    };

    const tabsContent = [
        { label: "All", key: "all" },
        { label: "Ongoing", key: "ONGOING" },
        { label: "Upcoming", key: "UPCOMING" },
        { label: "Completed", key: "COMPLETED" },
    ];

    const formatDate = (dateString) => {
        if (!dateString) return "";
        return dateString.split("T")[0];
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "DELETED":
            case "REJECTED":
                return "red";
            case "SUCCESS":
                return "green";
            case "ACCEPTED":
                return "purple";
            case "PENDING":
                return "yellow";
            case "COMPLETED":
                return "green"; // Assuming you want COMPLETED to have the same color as SUCCESS
            default:
                return "geekblue"; // Default color
        }
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "autoIncrementId",
            key: "id",
        },
        // {
        //     title: "Booking ID",
        //     dataIndex: "booking_details_unique_id",
        //     key: "booking_id",
        // },
        {
            title: "Service",
            dataIndex: ["bookings", "service_booking", "name"],
            key: "service",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (date) => formatDate(date),
        },
        {
            title: "Address",
            dataIndex: ["bookings", "booking_address", "full_address"],
            key: "address",
        },
        {
            title: "Client",
            dataIndex: ["bookings", "booking_user", "user_profile", "name"],
            key: "users",
        },
        {
            title: "Employees",
            dataIndex: "service_provider_details",
            key: "service_providers",
            render: (serviceProviders) => {
                const providerNames = serviceProviders
                    .map(provider => provider.service_provider_profile?.user_profile?.name)
                    .filter(name => name)
                    .join(", ");
                return providerNames ? providerNames : <span style={{ color: 'red', fontWeight: 300 }}>No Employees assigned</span>;
            },
        },
        {
          title: "Team Leader",
          dataIndex: "service_provider_details",
          key: "team_leader",
          render: (serviceProviders) => {
              const teamLeader = serviceProviders.find(provider => provider.is_team_leader);
              return teamLeader ? teamLeader.service_provider_profile.user_profile.name : <span style={{ color: 'red', fontWeight: 300 }}>No team leader assigned</span>;
          },
      },
        {
            title: "Status",
            dataIndex: "booking_status",
            key: "status",
            render: (status) => {
                const color = getStatusColor(status);
                return (
                    <Tag color={color}>{status === 'SUCCESS' ? 'COMPLETED' : status.toUpperCase()}</Tag>
                );
            },
        },
        {
			title: "Service Start Time",
			dataIndex: "service_start_time",
			key: "service_start_time",
			render: (time) => (time ? new Date(time).toLocaleString() : "---"),
		},
		{
			title: "Service End Time",
			dataIndex: "service_end_time",
			key: "service_end_time",
			render: (time) => (time ? new Date(time).toLocaleString() : "---"),
		},
        {
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{fontSize:"20px"}}
					className="redirect_button"
					onClick={() => navigate(`/viewBooking/${record.id}`)}
				/>
			),
		},
    ];

    const handleTabSelect = (k) => {
        setActiveTab(k);
        let status = k === "all" ? "all" : k;
        getData(status);
    };

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="30px"
            >
                <div>
                    <h3 className="page-title">EMPLOYEE MANAGEMENT</h3>
                    <p className="page-sub-title">View Information related with Employee</p>
                </div>
                <div>
                    <Button
                        icon="pi pi-arrow-left"
                        severity="secondary"
                        onClick={navigateToUser}
                        style={{ borderRadius: "5px", height: "47px" }}
                    >
                        <span style={{ marginLeft: "5px" }}>Return to Employee List</span>
                    </Button>
                </div>
            </Box>

            <Card>
                <div className="admin_profile">
                    <Form className="admin_details_form">
                        <div>
                            <h5 style={{ marginBottom: "20px" }}>
                                View all the bookings associated with{" "}
                                {userData?.user_profile?.name}
                            </h5>
                            <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                                {tabsContent.map((tab) => (
                                    <Tab eventKey={tab.key} title={tab.label} key={tab.key}>
                                        <div style={{ marginTop: "20px" }}>
                                            <Table
                                                columns={columns}
                                                dataSource={bookingData}
                                                rowKey="autoIncrementId"
                                            />
                                        </div>
                                    </Tab>
                                ))}
                            </Tabs>
                        </div>
                    </Form>
                </div>
            </Card>
        </Box>
    );
};

export default ViewCustomer;
